import React, { useState } from "react";
import { searchLocations } from "../api/weatherApi";

const SearchLocation = ({ onLocationSelect }) => {
  const [query, setQuery] = useState("");
  const [suggestions, setSuggestions] = useState([]);

  const handleSearch = async (e) => {
    const value = e.target.value;
    setQuery(value);

    if (value.length > 2) {
      try {
        const results = await searchLocations(value);
        setSuggestions(results);
      } catch (error) {
        console.error("Error searching locations:", error);
      }
    } else {
      setSuggestions([]);
    }
  };

  const handleSelect = (location) => {
    setQuery(location.name);
    setSuggestions([]);
    onLocationSelect({ lat: location.lat, lon: location.lon });
  };

  return (
    <div className="mb-8 relative">
      <input
        type="text"
        value={query}
        onChange={handleSearch}
        placeholder="Search location..."
        className="w-full p-2 rounded-md border border-gray-300 dark:border-gray-700 bg-white dark:bg-gray-800 text-gray-800 dark:text-white"
      />
      {suggestions.length > 0 && (
        <ul className="absolute z-10 w-full mt-1 bg-white dark:bg-gray-800 border border-gray-300 dark:border-gray-700 rounded-md shadow-lg">
          {suggestions.map((location) => (
            <li
              key={location.id}
              onClick={() => handleSelect(location)}
              className="p-2 hover:bg-gray-100 dark:hover:bg-gray-700 cursor-pointer text-gray-800 dark:text-white"
            >
              {location.name}, {location.country}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default SearchLocation;
