import React from "react";

const HourlyForecast = ({ data }) => {
  const next24Hours = data.slice(0, 24);

  return (
    <div className="bg-white dark:bg-gray-800 rounded-lg p-6 shadow-md">
      <h2 className="text-2xl font-semibold mb-4 text-gray-800 dark:text-white">
        Hourly Forecast
      </h2>
      <div className="flex overflow-x-auto space-x-4 pb-4">
        {next24Hours.map((hour, index) => (
          <div
            key={index}
            className="flex flex-col items-center bg-gray-100 dark:bg-gray-900 p-6 rounded-lg"
          >
            <p className="text-gray-600 dark:text-gray-400">
              {new Date(hour.dt * 1000).getHours()}:00
            </p>
            <img
              src={`https://openweathermap.org/img/wn/${hour.weather[0].icon}.png`}
              alt={hour.weather[0].description}
              className="w-10 h-10"
            />
            <p className="text-gray-800 dark:text-white font-semibold">
              {Math.round(hour.temp)}°C
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default HourlyForecast;
