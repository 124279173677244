import React from "react";

const DailyForecase = ({ data }) => {
  const next7Days = data.slice(0, 7);

  return (
    <div className="bg-white dark:bg-gray-800 rounded-lg p-6 shadow-md">
      <h2 className="text-2xl font-semibold mb-4 text-gray-800 dark:text-white">
        7-Day Forecast
      </h2>
      <div className="space-y-4">
        {next7Days.map((day, index) => (
          <div
            key={index}
            className="flex items-center justify-between bg-gray-100 dark:bg-gray-900 p-6 rounded-lg"
          >
            <p className="text-gray-600 dark:text-gray-400">
              {new Date(day.dt * 1000).toLocaleDateString("en-US", {
                weekday: "short",
              })}
            </p>
            <img
              src={`https://openweathermap.org/img/wn/${day.weather[0].icon}.png`}
              alt={day.weather[0].description}
              className="w-10 h-10"
            />
            <p className="text-gray-800 dark:text-white font-semibold">
              {Math.round(day.temp.max)}°C / {Math.round(day.temp.min)}°C
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default DailyForecase;
