import { useEffect, useState } from "react";
import { fetchWeatherData } from "./api/weatherApi";
import { ThemeProvider } from "./ThemeContext";
import Header from "./components/Header";
import SearchLocation from "./components/SearchLocation";
import WeatherDisplay from "./components/WeatherDisplay";

function App() {
  const [location, setLocation] = useState(null);
  const [weatherData, setWeatherData] = useState(null);

  useEffect(() => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLocation({
            lat: position.coords.latitude,
            lon: position.coords.longitude,
          });
        },
        (error) => {
          console.error("Error getting location:", error);
        }
      );
    }
  }, []);

  useEffect(() => {
    if (location) {
      fetchWeatherData(location.lat, location.lon)
        .then((data) => setWeatherData(data))
        .catch((error) => console.error("Error fetching weather data:", error));
    }
  }, [location]);

  const handleLocationSearch = (newLocation) => {
    setLocation(newLocation);
  };

  return (
    <ThemeProvider>
      <div className="min-h-screen bg-gray-100 dark:bg-gray-900 transition-colors duration-300">
        <div className="container mx-auto px-4 py-8">
          <Header />
          <SearchLocation onLocationSelect={handleLocationSearch} />
          {weatherData && <WeatherDisplay weatherData={weatherData} />}
        </div>
      </div>
    </ThemeProvider>
  );
}

export default App;
