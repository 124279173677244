import React from "react";

const CurrentWeather = ({ data }) => {
  return (
    <div className="bg-white dark:bg-gray-800 rounded-lg p-6 shadow-md">
      <h2 className="text-2xl font-semibold mb-4 text-gray-800 dark:text-white">
        Current Weather
      </h2>
      <div className="flex items-center justify-between">
        <div>
          <p className="text-4xl font-bold text-gray-800 dark:text-white">
            {Math.round(data.temp)}°C
          </p>
          <p className="text-gray-600 dark:text-gray-400">
            {data.weather[0].description}
          </p>
        </div>
        <img
          src={`https://openweathermap.org/img/wn/${data.weather[0].icon}@2x.png`}
          alt={data.weather[0].description}
          className="w-20 h-20"
        />
      </div>
      <div className="mt-4 grid grid-cols-2 gap-4">
        <p className="text-gray-600 dark:text-gray-400">
          Humidity: {data.humidity}%
        </p>
        <p className="text-gray-600 dark:text-gray-400">
          Wind: {data.wind_speed} m/s
        </p>
      </div>
    </div>
  );
};

export default CurrentWeather;
