import React from "react";
import CurrentWeather from "./CurrentWeather";
import HourlyForecast from "./HourlyForecast";
import DailyForecase from "./DailyForecase";

const WeatherDisplay = ({ weatherData }) => {
  return (
    <div className="space-y-8">
      <CurrentWeather data={weatherData.current} />
      <HourlyForecast data={weatherData.hourly} />
      <DailyForecase data={weatherData.daily} />
    </div>
  );
};

export default WeatherDisplay;
