const API_KEY = "eb9f385c520a9ce5f56d1ce9a27b7f0f";
const BASE_URL = "https://api.openweathermap.org/data/";

export const fetchWeatherData = async (lat, lon) => {
  const response = await fetch(
    `${BASE_URL}/3.0/onecall?lat=${lat}&lon=${lon}&units=metric&appid=${API_KEY}`
  );
  if (!response.ok) {
    throw new Error("Failed to fetch weather data");
  }
  return response.json();
};

export const searchLocations = async (query) => {
  const response = await fetch(
    `${BASE_URL}/2.5/find?q=${query}&type=like&sort=population&cnt=5&appid=${API_KEY}`
  );
  if (!response.ok) {
    throw new Error("Failed to search locations");
  }
  const data = await response.json();
  return data.list.map((item) => ({
    id: item.id,
    name: item.name,
    country: item.sys.country,
    lat: item.coord.lat,
    lon: item.coord.lon,
  }));
};
